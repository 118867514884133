import { Col, Row } from "reactstrap"
const Story = () => {
  return (
    <section
      className="row d-flex md-2 elementor-element elementor-element-c5b5333 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
      data-id="c5b5333"
      data-element_type="section"
    >
      <div
        className="elementor-element elementor-element-9443d09 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading"
        data-id="9443d09"
        data-element_type="widget"
        data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_scalex":{"unit":"px","size":0.7,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":0.7,"sizes":[]},"":"false","avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
        data-widget_type="heading.default"
      >
        <div className="elementor-widget-container">
          <h2 className="elementor-heading-title elementor-size-default">
            Our Story
          </h2>
        </div>
      </div>
      <div className="elementor-container elementor-column-gap-default padding-bawah-our-story">
        <div className="row-story elementor-row">
          <div
            className="column-story col elementor-element elementor-element-33379ae elementor-column elementor-col-50 elementor-inner-column"
            data-id="33379ae"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap padding-content-aboutus-left">
                <div
                  className="elementor-element elementor-element-af1a2a9 elementor-widget elementor-widget-text-editor line-height-our-story1"
                  data-id="af1a2a9"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix fontsize-aboutus-story">
                      <p align="justify">
                        Pharma Metric Labs is an independent Contract Research
                        Organization (CRO) providing full range of contract
                        services including Clinical Trial, Bioavailability/
                        Bioequivalence (BA/BE) studies, and Contract Analysis to
                        major companies globally.
                        <br />
                        <br />
                        Founded in 2005 and headquartered in Jakarta, Indonesia,
                        we are committed to provide accurate, reliable, timely,
                        and cost-effective services for our customer with
                        integrated clinical and analytical sites.
                        <br />
                        <br />
                        We are connected to local universities and hospitals,
                        experienced clinical specialists, and other strategic
                        partnership with International CROs, as well as
                        supported by in-house experts for regulatory affairs,
                        project management, data management, as well as quality
                        assurance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div
						className="column-story col elementor-element elementor-element-ebb27ae elementor-column elementor-col-50 elementor-inner-column"
						data-id="ebb27ae"
						data-element_type="column"
						data-settings="{&quot;&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
					>
						<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap padding-content-aboutus-right">
								<div
									className="elementor-element elementor-element-abd2005 elementor-widget elementor-widget-text-editor"
									data-id="abd2005"
									data-element_type="widget"
									data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:50,&quot;sizes&quot;:[]},&quot;&quot;:&quot;false&quot;,&quot;avante_ext_smoove_duration&quot;:400,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="text-editor.default"
								>
									<div className="elementor-widget-container">
										<div className="elementor-container elementor-column-gap-default our-story">
											<div className="elementor-row story-icon">
												<div
													className="elementor-element elementor-element-8601bb1 elementor-column elementor-col-30 elementor-top-column elementor-AboutUs"
													data-id="8601bb1"
													data-element_type="column"
													data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
												>
													<div className="elementor-column-wrap elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div
																className="elementor-element elementor-element-aab0399 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
																data-id="aab0399"
																data-element_type="widget"
																data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
																data-widget_type="heading.default"
															>
																<div className="elementor-widget-container">
																	<h2 className="elementor-heading-title elementor-size-default" id="story-icon">
																		<i class="far fa-eye fa-3x"></i>
																		<p>Our Vision</p>
																	</h2>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div
													className="elementor-element elementor-element-7bc3d66 elementor-column elementor-col-70 elementor-top-column elementor-AboutUs"
													data-id="7bc3d66"
													data-element_type="column"
													data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
												>
													<div className="elementor-column-wrap elementor-element-populated">
														<div className="elementor-widget-wrap padding-story-description">
															<div
																className="elementor-element elementor-element-118d518 elementor-widget elementor-widget-heading"
																data-id="118d518"
																data-element_type="widget"
																data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
																data-widget_type="heading.default"
															>
																<div className="elementor-widget-container">
																	<span className="elementor-heading-title elementor-size-default">
																		To become World Class Contract Research Organization
																		recognized by local and international customer.
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="elementor-row story-icon">
												<div
													className="elementor-element elementor-element-8601bb1 elementor-column elementor-col-30 elementor-top-column elementor-AboutUs"
													data-id="8601bb1"
													data-element_type="column"
													data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
												>
													<div className="elementor-column-wrap elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div
																className="elementor-element elementor-element-aab0399 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
																data-id="aab0399"
																data-element_type="widget"
																data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
																data-widget_type="heading.default"
															>
																<div className="elementor-widget-container">
																	<h2 className="elementor-heading-title elementor-size-default" id="story-icon">
																		<i class="fas fa-bullseye fa-3x"></i>
																		<p>Our Mission</p>
																	</h2>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div
													className="elementor-element elementor-element-7bc3d66 elementor-column elementor-col-70 elementor-top-column elementor-AboutUs"
													data-id="7bc3d66"
													data-element_type="column"
													data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
												>
													<div className="elementor-column-wrap elementor-element-populated">
														<div className="elementor-widget-wrap padding-story-description">
															<div
																className="elementor-element elementor-element-118d518 elementor-widget elementor-widget-heading"
																data-id="118d518"
																data-element_type="widget"
																data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
																data-widget_type="heading.default"
															>
																<div className="elementor-widget-container">
																	<span className="elementor-heading-title elementor-size-default">
																		To ensure good quality of health care product for the community.
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="elementor-row story-icon">
												<div
													className="elementor-element elementor-element-8601bb1 elementor-column elementor-col-30 elementor-top-column elementor-AboutUs"
													data-id="8601bb1"
													data-element_type="column"
													data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
												>
													<div className="elementor-column-wrap elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div
																className="elementor-element elementor-element-aab0399 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
																data-id="aab0399"
																data-element_type="widget"
																data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
																data-widget_type="heading.default"
															>
																<div className="elementor-widget-container">
																	<h2 className="elementor-heading-title elementor-size-default" id="story-icon">
																		<i class="fas fa-hands fa-3x"></i>
																		<p>Our Value</p>
																	</h2>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div
													className="elementor-element elementor-element-7bc3d66 elementor-column elementor-col-70 elementor-top-column elementor-AboutUs"
													data-id="7bc3d66"
													data-element_type="column"
													data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
												>
													<div className="elementor-column-wrap elementor-element-populated">
														<div className="elementor-widget-wrap padding-story-description">
															<div
																className="elementor-element elementor-element-118d518 elementor-widget elementor-widget-heading"
																data-id="118d518"
																data-element_type="widget"
																data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
																data-widget_type="heading.default"
															>
																<div className="elementor-widget-container">
																	<span className="elementor-heading-title elementor-size-default">
																		Improve knowledge and services for a better life.
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}
        </div>
      </div>
      <Row className="d-flex text-center mt-5">
        <Col md={4} sm={12}>
          <div>
            <i class="far fa-eye fa-3x" />
            <h2 className="vismis-title">Our Vision</h2>
            <p className="vismis-desc">
              To become World Class Contract Research Organization recognized by
              local and international customer
            </p>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div>
            <i class="fas fa-bullseye fa-3x" />
            <h2 className="vismis-title">Our Mission</h2>
            <p className="vismis-desc">
              To ensure good quality of health care product for the community
            </p>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div>
            <i class="fas fa-hands fa-3x" />
            <h2 className="vismis-title">Our Value</h2>
            <p className="vismis-desc">
              Deliver Best Services Through Strong Experiences and Innovation
            </p>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default Story
