import PartnersSlider from "../../sliders/PartnersSlider"

const Partners = () => {
  // const partners = [
  //   {
  //     id: 1,
  //     // name: "John Smith",
  //     testimony: "Pedoman Cara Uji Klinik yang Baik",
  //     image: "",
  //     company:
  //       "The National Agency of Drug and Food Control Jakarta: BPOM RI. 2016.",
  //   },
  //   {
  //     id: 2,
  //     // name: "John Smith",
  //     testimony: "Guideline for Good Clinical Practice E6 (R2)",
  //     image: "",
  //     company:
  //       "ICH Expert Working Group. ICH Harmonised Tripartite Guideline, 2016.",
  //   },
  //   {
  //     id: 3,
  //     // name: "John Smith",
  //     testimony: "Good Laboratory Practice Principles",
  //     image: "",
  //     company:
  //       "(ISO/IEC 17025)/GLP General requirements for the competence of testing and calibration laboratories, 2017.",
  //   },
  //   {
  //     id: 4,
  //     // name: "John Smith",
  //     testimony: "Guideline on the Investigation of Bioequivalence",
  //     image: "",
  //     company:
  //       "European Medicines Agency, Doc. Ref. CPMP/EWP/QWP/1401/98 Rev. 1. 2010.",
  //   },
  //   {
  //     id: 5,
  //     // name: "John Smith",
  //     testimony: "Ethical Principles for Medical Research Involving Human Subjects",
  //     image: "",
  //     company:
  //       "World Medical Association Declaration Of Helsinki Scotland: 52nd WMA General Assembly, 2000.",
  //   },
  //   {
  //     id: 6,
  //     // name: "John Smith",
  //     testimony: "Guidance for Industry Bio Analytical Method Validation",
  //     image: "",
  //     company:
  //       "U.S. Department of Health and Human Services, Food and Drug Administration, Center for Drug Evaluation and Research (CDER), Center for Veterinary Medicine (CVM), May 2001.",
  //   },
  // ]
  return (
    <section className="slider">
      <div className="container">
        <h2 className="title-heading">Quality & Ethic Standards Partners</h2>
        <h5 className="">
          To ensure the best quality for our studies, we follow and implement
          the guidelines provided by high standing institutions around the
          world.
        </h5>
        <PartnersSlider />
      </div>
    </section>
  )
}

export default Partners

{
  /* <ContentSlider
        contents={testimonies}
        blogPostCls="post-style-1"
        imgEffect="rotate"
      /> */
}
